$statusCompleteIndicatorColor: #00a65a;
$statusNotCompleteIndicatorColor: #dd4b39;
$statusNotSetIndicatorColor: #000000FF;

.sidebar {
  .far.black {
    color: $statusNotSetIndicatorColor;
  }

  .far.green {
    color: $statusCompleteIndicatorColor;
  }

  .far.red {
    color: $statusNotCompleteIndicatorColor;
  }

}
