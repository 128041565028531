#roofPlanner-iframe {
  position: fixed;
  top: 80px;
  bottom: 0px;
  right: 0px;
  width: 95%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 900;
  height: calc(90% - 80px);
}

#roofPlanner-back-link {
  position: fixed;
  bottom: 20px;
  z-index: 950;
  left: 100px;
}

