.mi-recurring-calendar {
  &:before {
    background-size: 14px 14px;
    height: 15px;
    width: 15px;
    margin-bottom: -3px;
    background-repeat: no-repeat;
    background-image: url("../../assets/recurring-calendar.svg");
    display: inline-block;
    content: "";
  }

  &:hover::before {
    background-image: url("../../assets/recurring-calendar-hover.svg");
  }
}