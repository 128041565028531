@import 'variables';
@import 'mixins';

.calendar-filter-configuration {
  display: flex;
  column-gap: 2rem;
}

#calendar-container {
  position: relative;
  z-index: 1;
  margin-left: 200px;
}

#calendar {
  max-width: 1100px;
  margin: 20px auto;
}

.calendar-wrapper {
  background: #ffffff;
  min-height: 80vh;
}

.izen-calendar-event-content {
  padding: 0.5rem 0.5rem;
  font-size: 0.875rem;
}

.modify-selection {
  .select-all {
    padding: 8px 6px;
    color: rgb(204, 204, 204);

    &:hover {
      color: rgb(153, 153, 153);
    }

    & > i {
      width: 20px;
      height: 20px;
    }
  }
}

.izen-calendar-sidebar {

  .izen-calendar-events-container {
    height: 45%;
  }

  .izen-calendar-actions {
    position: absolute;
    bottom: 0;
    right: 15px;
  }
}

.izen-calendar-events-container {
  .fc-event {
    cursor: move;
  }
}

.fc-event.izen-calendar-event {
  @each $eventType, $backgroundColor in $calendar-event-colors {
    @include calendar-event-color("&.izen-calendar-event-#{$eventType}", $backgroundColor);
  }

  .navigate-to-booking {
    text-decoration: none;
    color: inherit;
  }
}

.calendar-view .tooltip-inner {
  white-space: nowrap;
  text-align: start;
  max-width: 350px;
  width: 350px;
}

.bg-holiday{
  background-color: #aeaeae;
}

#calendar-loading {
  position: absolute;
  left: -1%;
  top: -1%;

  width: 102%;
  height: 102%;
  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 20%);
}

#calendar-loading > .wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, #68b42d, #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}

#calendar-loading > .wave:nth-child(2) {
  animation-delay: 0.1s;
}

#calendar-loading > .wave:nth-child(3) {
  animation-delay: 0.2s;
}

#calendar-loading > .wave:nth-child(4) {
  animation-delay: 0.3s;
}

#calendar-loading > .wave:nth-child(5) {
  animation-delay: 0.4s;
}

#calendar-loading > .wave:nth-child(6) {
  animation-delay: 0.5s;
}

#calendar-loading > .wave:nth-child(7) {
  animation-delay: 0.6s;
}

#calendar-loading > .wave:nth-child(8) {
  animation-delay: 0.7s;
}

#calendar-loading > .wave:nth-child(9) {
  animation-delay: 0.8s;
}

#calendar-loading > .wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
