@import "components/star-rating";
@import "components/roofPlanner";
@import "components/status-indicators";
@import "components/calendar";
@import "components/utilities";
@import "components/icons";
@import "components/signature";

/*hide logo on login screen*/
.form-signin .page-header {
  background: none;
}

.entity-query-filter-form .entity-query-filter-form-basic .form-group.form-group {
  margin-bottom: 0.5em;
  margin-right: 1em;
}

/* selects unscrollable in filter controls (?) */
.entity-query-filter-form {
  .bootstrap-select.dropdown > .dropdown-menu.show {
    overflow-y: auto !important; // library issue with overflow being fixed to hidden?
  }
}


/*width of postal code dropdown on team detail*/
/*.dropdown-item span.text {
  max-width: 30rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.bootstrap-select .dropdown-menu ul.dropdown-menu.inner.show {
  margin-bottom: 0 !important;
}

.bootstrap-select .dropdown-menu {
  max-width: 33rem !important;
}*/

.bg-success-light {
  background-color: #5bb42d75 !important;
}

.sidebar-badge-primary {
  float: right;
  color: white;
}

.fa-slash-icon:after {
  position: relative;
  content: "/";
  font-weight: 700;
  font-size: 1.7em;
  left: -12px;
  top: 2px;
}

.event--selected {
  border: 4px solid green !important;;
}

.splide__slide img {
  width: 30rem;
  height: auto;
}

.slider-initializer {
  width: 30rem;
}

.inline-form-group {
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  .splide__slide img {
    width: 8rem;
    height: auto;
  }

  .slider-initializer {
    width: 8rem;
  }
}


.embedded-collection-item {
  border: 1px solid lightgray;
  padding: 1em;
  margin-bottom: 1em;
}


.flatpickr-readonly {
  background: white !important;
}

.flatpickr-months .flatpickr-month {
  background: #68b42d;
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: rgb(104, 180, 45);
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: #68b42d;
}

.flatpickr-weekdays {
  background: #68b42d;
}

span.flatpickr-weekday {
  background: #68b42d;
}

.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
  background: #68b42d;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #68b42d;
}

i.fas.fa-times.axu-text-danger {
  font-size: 1.5em;
}

.calendar-view {
  padding-top: 80px !important;
}

.d-flex.calendar-view {
  padding-top: 0 !important;
}

label {
  font-weight: 600;
  white-space: break-spaces !important;
}

.empty-form-group {
  margin: 0 !important;
  padding: 0 !important;
}