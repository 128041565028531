@mixin calendar-event-color($component, $color) {
  #{$component} {
    background-color: $color;
    border-color: $color;
  }

  #{$component}.selected-event {
    background-color: darken($color, 30%);
    border-color: darken($color, 30%);
  }
}