// https://www.materialui.co/colors
$matui-flat-light-green-300: rgba(174, 213, 129, 1);
$matui-flat-light-blue-300: rgba(79, 195, 247, 1);
$matui-flat-purple-300: rgba(186, 104, 200, 1);
$matui-flat-orange-400: rgba(255, 167, 38, 1);
$matui-flat-blue-grey-200: rgba(176, 190, 197, 1);
$matui-flat-grey-500: rgba(158, 158, 158, 1);

$calendar-event-colors: ();

$calendar-event-colors: map_merge(
                (
                        "advice": $matui-flat-light-blue-300,
                        "advice_online": $matui-flat-light-blue-300,
                        "advice_home": $matui-flat-light-blue-300,
                        "inspection": $matui-flat-blue-grey-200,
                        "installation": $matui-flat-purple-300,
                        "control": $matui-flat-light-green-300,
                        "intervention": $matui-flat-orange-400,
                        "unavailable": $matui-flat-grey-500,
                        "national_holiday": $matui-flat-grey-500
                ),
                $calendar-event-colors
);

